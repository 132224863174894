export const data = {
  panelImageName: "publicitarios-bg.png",
  divisorText: "Contabilidade para Publicitários",
  content: {
    imageName: "PUBLICITARIO.jpg",
    title: "Como ser nosso cliente?",
    text:
      "Atendemos publicitários e nos encantamos com a criatividade desses profissionais. Acreditamos que podemos contribuir muito com a segurança do seu negócio.",
    options: [
      "Orientações",
      "Análise Financeira",
      "Análise de Riscos",
      "Assessoria Tributária",
      "Legalização",
    ],
    contactText:
      "Tenha uma contabilidade personalizada, cadastre-se e receba nosso contato",
  },
}
