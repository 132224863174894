import React from "react"

import SEO from "../components/seo"

import Layout from "../components/Layout"
import BlankPage from "../components/BlankComponents/BlankPage"
import { data } from "../pagesContent/publicitario"

const Publicitarios = () => (
  <Layout>
    <SEO title="Publicitarios" />
    <BlankPage data={data} />
  </Layout>
)

export default Publicitarios
